body {
  font-family: "poppins";
  margin: 0;
  font-size: 13px;
}

button {
  border: none;
}

.splash{
  background-image: url(./images/splash.png);
  background-size: cover;
  background-attachment: fixed;
  max-width: 500px;
  margin: 0 auto;
  height: 100vh;
  padding-top:25%;
}
.screen {
  position: relative;
  max-width: 500px;
  margin: 0 auto;
  height: 100vh;
  background: #ececec;
}
.back-arrow {
  height: 20%;
  width: auto;
  position: absolute;
  top: 8%;
  left: 15px;
}
.next-arrow {
  height: 20%;
      width: auto;
      position: absolute;
      top: 8%;
      right: 15px;
}
.back-arrow img, .next-arrow img {
  height: 100%;
  width: auto;
}
.logo {
  width: 50%;
  margin: 0 auto;
}
.image30 {
  width: 30%;
  margin: 0 auto;
}
.image15 {
  width: 15%;
  margin: 0 auto;
}
.logo-blue {
  width: 50%;
  margin: 0 auto;
}
.responsive {
  width: 100%;
  height: auto;
}
.go-left {
  float: left;
}
.topbar {
  height: 7.5%;
  width: 100%;
  float: left;
  display: flex;
  align-items: center;
  border-bottom: 1px solid #f4f4f4;
  position: relative;
  padding: 20px 0;
  background: rgb(71,134,251);
}
.left-text {
  float: left;
      text-align: left;
      width: 53%;
      line-height: 1.5;
      color: #777;
      padding-left: 7%;
}

.btn-invert {
    border: 1px solid #1d8cbb;
    background-color: #fff;
    color: #1d8cbb;
}
.btn-green {
    background-color: #37ce00;
    color: #fff;
}
.border-line {
  border-bottom:1px solid #e6e6e6;
  margin: 10px 7%;
  float: left;
  width: 86%;
}
.right-text {
  float: left;
  text-align: right;
  width: 33%;
  color: #222;
  padding-right: 7%;
  font-weight: 700;
}
.progress {
  width: 100%;
float: left;
display: flex;
align-items: center;
justify-content: center;
}
.progress img {
  width: 20%;
  margin-top: 10px;
}
.stage-bar {
  width: 10px;
      margin: 0 2px;
      background-color: #fff;
      opacity: 20%;
      height: 10px;
      border-radius: 5px;

}
.active-bar {
  opacity: 1;
}
.stage-hero {
  width: 100%;
  float: left;
  height: auto;
  background: #fff;
}
.white-back {
  background-color: #fff!important;
}
.start-hero {
  background-image:url(images/start-hero.png);
  background-size: cover;
}
.hero-90 {
  width: 100%;
  float: left;
  top: 7.5%;
  bottom: 7.5%;
  position: fixed;
  overflow: scroll;
  left: 0;
  right: 0;
}
.bottom-spacer {
  height: 30px;
  float: left;
  width: 100%;
}
.step1-bg {
  background-size: cover;
  background: rgb(71,134,251);
}
.step2-bg {
  background-image:url(images/step2-bg.png);
  background-size: cover;
  background: rgb(71,134,251);
}
.step3-bg {
  background-image:url(images/step3-bg.png);
  background-size: cover;
  background: rgb(71,134,251);
}
.hero-text {
  width: calc(100% - 60px);
  float: left;
  padding:3% 30px;
  line-height: 1.4;
  text-align: center;
  color: #666;
}
.blue-background {
  background-color: #ececec;
}
.bottom-zero {
  margin-bottom: 0;
  padding-bottom: 0;
}
.full-width {
  width:100%;
  float: left;
}
.btn {
  width: 90%;
  margin-left: 5%;
  padding: 10px 0;
  text-transform: uppercase;
  float: left;
  text-align: center;
  text-decoration: none;
  font-weight: 300;
  font-family: "poppins";
  border-radius: 30px;
}

.thanks-btn {
  width: 80%!important;
  margin-left: 10%!important;
  margin-top: 20px;
}
.btn-blue {
  background-color: #4886fb;
  color: #fff;
}
.btn-red {
  background-color: rgb(71,134,251);
  color: #fff;
}
.btn-white {
  background: rgb(255 255 255 / 65%);
    color: rgb(71,134,251);
}
.white-font {
  color: #fff;
}
.top15 {
  margin-top: 15px
}
.top30 {
  margin-top: 30%;
}
.top15-p {
  margin-top: 15%;
}
.top9-p {
  margin-top: 9%;
}
.top15-pm {
  padding-top: 15%;
}
.top10-pm {
  padding-top: 5%;
}
.bottom10-p {
  margin-bottom: 10%;
}
.blue-back {
  background: rgb(71,134,251);
}
.bottom10-pm {
  padding-bottom: 10%;
}
.bottom06-p {
  margin-bottom: 6%;
}
.top60-p {
  margin-top: 60%;
}
.hero-white {
  color: #fff;
  text-align: center;
  float: left;
  width: 100%;
}
.hero-img {
  width: 30%;
      margin-left: 35%;
      float: left;
      margin-top: 22%;
}
.title {
  color: #fff;
  width: 100%;
  line-height: 1;
  text-align: center;
  text-transform: uppercase;
}

.back-arrow {
    height: 20%;
    width: auto;
    position: absolute;
    top: 8%;
    left: 15px;
}
.geXTrK {
    height: 7.5%;
    width: 100%;
    float: left;
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-align-items: center;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    position: fixed;
    background: rgb(71,134,251);
    border-bottom: 1px solid rgb(255 255 255 / 15%);
    z-index: 5;
    left: 0;
    right: 0;
}
.gray{
  background: rgb(71,134,251);
}
.textbox {
  background: #ffffff;
  border-bottom: 1px solid #dee8fb;
  width: 75%;
  margin-left: 10%;
  border-top: 0;
  padding: 10px 2.5%;
  font-family: "poppins";
  border-left: none;
  border-right: none;
  font-size: 13px;
  text-transform: none!important;
  text-align: center;

}
.selector {
    background: #f4f8ff;;
    width: 80%;
    border-bottom: 0px solid #fff;
    margin-left: 10%;
    border-top: 0;
    padding: 10px 2.5%;
    font-family: "poppins";
    border-left: none;
    border-right: none;
    font-size: 13px;
}
::placeholder { /* Firefox 18- */
  color: rgb(72 134 251 / 57%);
  font-weight: 300;

}

.home-icon {
  width: calc(90% - 5px);
  padding: 2%;
  margin-left: 3%;
  float: left;
  background-color: #fff;
  margin-bottom: 20px;
  border-radius: 8px;
  box-shadow: -0px 1px 6px 0px rgba(0,0,0,0.18);
position: relative;
}
.alert-good {
  border-left: 4px solid #13bb13;
}
.alert-caution {
  border-left: 4px solid orange;
}
.alert-bad {
  border-left: 4px solid red;
}
.alert-neutral {
border-left: 4px solid  #4786fb;
}
.color-good {
  color: #13bb13;
}
.color-neutral {
  color: #4786fb;
}
.color-caution {
  color: orange;
}
.color-bad {
  color: red;
}
.icon-house {
  width: 15%;
  border: 1px solid rgb(255 255 255 / 1%);;
  float: left;
  border-radius: 4px;

}
.home-item {
  width: 64%;
  padding:1% 1% 0 3%;
  float: left;
}
.insured-title {
    width: 100%;
    float: left;
    font-size: 12px;
    margin-top: 3px;
    line-height: 1.1;
    color: #666;
}
.insured-status {
  width: 100%;
  float: left;
  font-size: 11px;
  margin-top: -2px;
}
.bolda {
  font-weight: 700;
  color:#555;
  text-transform: uppercase;
}
.price {
  position:absolute;
  top:30%;
  right: 17%;
  text-align: right;
  font-size: 15px;
  font-weight: 700;
  color: #333;
  width: auto;
}
.icon {
  width: 90%;
  margin-left: 5%;
}
.icon img {
  opacity: 0.2
}
.unread {
  opacity: 1!important;
}
.width-15pad {
  width: calc(100% - 30px);
  padding: 0 15px;
}
.footer {
  height: 7.5%;
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  background: #fff;
}

.footer-icon {
width: 33.33%;
float: left;
height: 100%;
display: flex;
align-items: center;
}
.footer-link {
  height: 75%;
  margin-left: 37%;
}
